<template>
  <section class="count-footer-menu icon-foo1">
    <nav class="menu">
      <ul class="menu-items">
        <li class="menu-item">
          <router-link :to="{ name: `r_count-export-${currentPage}` }">
            <icon icon="#cx-men1-download-sample" />
            {{ displayLabelName("count", "footer", "export") }}
          </router-link>
        </li>
        <li class="menu-item central-item icon-foo2">
          <button @click="getScreenshot">
            <span class="svg-icon">
              <svg>
                <use xlink:href="#cx-foo2-image-38x38"></use>
              </svg>
            </span>
          </button>
        </li>
        <li class="menu-item">
          <button class="disabled" disabled>
            <icon icon="#cx-foo1-5d-maps-28x28" />
            {{ displayLabelName("count", "footer", "map") }}
          </button>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
import domtoimage from "dom-to-image-more";
import { DateTime } from "luxon";

export default {
  name: "CountFooterMenu",
  data() {
    return {
      currentPage: this.$route.path.split("/")[2]
    };
  },
  methods: {
    getScreenshot() {
      const title = this.displayLabelName(
        "count",
        "footer",
        "clebex-count-screenshot"
      );

      const currentDateTime = DateTime.now().toFormat("dd.MM.yyyy HH.mm.ss");

      const currentPageLabel = this.displayLabelName(
        "count",
        "reports",
        this.currentPage
      );

      const fileName = `${title} ${currentDateTime} (${currentPageLabel}).png`;

      const el = document.getElementsByClassName("screen-content")[1];

      domtoimage.toPng(el).then(function(dataUrl) {
        let link = document.createElement("a");
        link.style.display = "none";
        link.download = fileName;
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(dataUrl);
      });
    }
  }
};
</script>
